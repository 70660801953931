export const PATH = {
  home: '/',

  login: '/login',
  register: '/register',
  forgotPassword: '/forgot_password',
  resetPassword: '/reset_password',
  verifyEmail: '/verify_email',
  signupUserComplete: '/signup_user_complete',
  resendEmail: '/resend_email',
  acceptInvitation: '/accept_invitation',

  device: '/device',

  security: '/security',

  orderHistory: '/order_history',
  paymentAndAddress: '/payment_and_address',
  servicesAndSubscriptions: '/services_and_subscriptions',

  notFound: '*',
};
