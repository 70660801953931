export const MODAL_IDS = {
  logout: 'logout',
  logoutWhenChangeEmail: 'logoutWhenChangeEmail',

  changeAvatar: 'changeAvatar',
  changeName: 'changeName',
  changeEmail: 'changeEmail',
  changePhoneNumber: 'changePhoneNumber',
  changePassword: 'changePassword',
  deleteAccount: 'deleteAccount',

  successfulNotification: 'successfulNotification',

  twoFactorAuthentication: 'twoFactorAuthentication',
};
