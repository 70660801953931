import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { ILogin, IRegister, IUser } from 'src/types/auth';
import { IUserPatch } from 'src/types/my_account';
import { ISession } from 'src/types/session';
import { toastMessage } from 'src/utils/toast';

export const getMe = createAsyncThunk<IUser, void>(
  'myAccount/getMe',
  async (_, { rejectWithValue }) => {
    try {
      const data = await client.getMe();

      return {
        ...data,
        avatarUrl: !data.avatarUrl
          ? 'https://images.pexels.com/photos/4059469/pexels-photo-4059469.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
          : data.avatarUrl,
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const registerUser = createAsyncThunk<{ token: string }, IRegister>(
  'myAccount/register',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.register(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const SignUpUserComplete = createAsyncThunk<any, { token: string; data: IRegister }>(
  'myAccount/SignUpUserComplete',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.SignUpUserComplete(payload.token, payload.data);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const login = createAsyncThunk<{ token: string }, ILogin>(
  'myAccount/login',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.login(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const logout = createAsyncThunk('myAccount/logout', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await client.logout();
    return data;
  } catch (error: any) {
    toastMessage.error(error.message);
    return rejectWithValue(error);
  }
});

export const patchUser = createAsyncThunk<IUser, { userId: string; data: IUserPatch }>(
  'myAccount/patchUser',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.patchUser(payload.userId, payload.data);
      return {
        ...data,
        avatarUrl: !data.avatarUrl
          ? 'https://images.pexels.com/photos/4059469/pexels-photo-4059469.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
          : data.avatarUrl,
      };
    } catch (error: any) {
      // toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const updateAvatar = createAsyncThunk<{ id: string; path: string }[], FormData>(
  'myAccount/updateAvatar',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateAvatar(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getUserSessions = createAsyncThunk<ISession[], string>(
  'myAccount/getUserSessions',
  async (userId, { rejectWithValue }) => {
    try {
      const data = await client.getUserSessions(userId);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const sendVerifyCode = createAsyncThunk<{ message: string }, string>(
  'myAccount/sendVerifyCode',
  async (userId, { rejectWithValue }) => {
    try {
      const data = await client.sendVerifyCode(userId);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const resetPassword = createAsyncThunk<any, { newPassword: string; token: string }>(
  'myAccount/resetPassword',
  async (payload, { rejectWithValue }) => {
    try {
      await client.resetPassword(payload.newPassword, payload.token);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const verifyCode = createAsyncThunk<string, { userId: string; code: string }>(
  'myAccount/verifyCode',
  async ({ userId, code }, { rejectWithValue }) => {
    try {
      const response = await client.verifyCode(userId, code);
      return response;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const updatePassword = createAsyncThunk<
  { message: string },
  { userId: string; password: string; newPassword: string }
>('myAccount/updatePassword', async (payload, { rejectWithValue }) => {
  try {
    const response = await client.updatePassword(
      payload.userId,
      payload.password,
      payload.newPassword,
    );
    return response;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const sendForgotPasswordToEmail = createAsyncThunk<any, string>(
  'myAccount/forgotPassword',
  async (email, { rejectWithValue }) => {
    try {
      const data = await client.sendForgotPasswordToEmail(email);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const revokeSession = createAsyncThunk<any, { userId: string; sessionId: string }>(
  'myAccount/revokeSession',
  async (payload, { rejectWithValue }) => {
    try {
      await client.revokeSession(payload.userId, payload.sessionId);
      return payload.sessionId;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const sendGenerateMFA = createAsyncThunk<any, { password: string; userId: string }>(
  'myAccount/generateMFA',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.sendGenerateMFA(payload.userId, payload.password);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateMFA = createAsyncThunk<any, { activate: boolean; code: string; userId: string }>(
  'myAccount/updateMFA',
  async (payload, { rejectWithValue }) => {
    try {
      await client.updateMFA(payload.userId, payload.code, payload.activate);
    } catch (error: any) {
      // toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const verifyEmail = createAsyncThunk<any, string>(
  'myAccount/verifyEmail',
  async (payload, { rejectWithValue }) => {
    try {
      await client.verifyEmail(payload);
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const resendEmail = createAsyncThunk<any, string>(
  'myAccount/resendEmail',
  async (email, { rejectWithValue }) => {
    try {
      await client.resendEmail(email);
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteAccount = createAsyncThunk<void, { userId: string; password: string }>(
  'myAccount/deleteAccount',
  async (payload, { rejectWithValue }) => {
    try {
      await client.deleteAccount(payload.userId, payload.password);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateUserLocale = createAsyncThunk<
  void,
  { userId: string; data: { locale: string } }
>('myaAccount/updateUserLocale', async (payload, { rejectWithValue }) => {
  try {
    await client.patchUser(payload.userId, payload.data);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
