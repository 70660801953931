import {
  AccountCircle,
  AccountCircleOutlined,
  DevicesOther,
  DevicesOtherOutlined,
  Lock,
  LockOutlined,
} from '@mui/icons-material';

import { IMenuRoute } from 'src/types/route';
import { PATH } from './path';

export const MenuList: IMenuRoute[] = [
  {
    id: 'label.personalInfo.main',
    path: PATH.home,
    icon: AccountCircleOutlined,
    activeIcon: AccountCircle,
    isHaveMenu: true,
    permission: [],
  },
  {
    id: 'label.security.main',
    path: PATH.security,
    icon: LockOutlined,
    activeIcon: Lock,
    isHaveMenu: true,
    permission: [],
  },
  {
    id: 'label.device.main',
    path: PATH.device,
    icon: DevicesOtherOutlined,
    activeIcon: DevicesOther,
    isHaveMenu: true,
    permission: [],
  },

  // {
  //   id: 'common.routes.orderHistory',
  //   path: PATH.orderHistory,
  //   icon: HistoryOutlined,
  //   activeIcon: History,
  //   isHaveMenu: true,
  //   permission: [],
  // },
  // {
  //   id: 'common.routes.paymentAndAddress',
  //   path: PATH.paymentAndAddress,
  //   icon: PaymentsOutlined,
  //   activeIcon: Payments,
  //   isHaveMenu: true,
  //   permission: [],
  // },
  // {
  //   id: 'common.routes.servicesAndSubscriptions',
  //   path: PATH.servicesAndSubscriptions,
  //   icon: MiscellaneousServicesOutlined,
  //   activeIcon: MiscellaneousServices,
  //   isHaveMenu: true,
  //   permission: [],
  // },
];
