import { Box, CircularProgress, Divider } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Sidebar from 'src/components/sidebar';
import Topbar from 'src/components/topbar';
import { useIsRequestPending } from 'src/hooks';
import { PATH } from 'src/routes/path';
import { RouteList } from 'src/routes/route_list';
import { useStyles } from './style';
import i18n from 'src/i18n';

const AdminLayout = (props: PropsWithChildren) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  const token = Cookies.get('USER_SESSION');

  const isLoadingGetMe = useIsRequestPending('myAccount', 'getMe');

  document.title = i18n.t('label.danapodAccount');

  const getTitle = () => {
    if (!location.pathname) return '';
    const path = location.pathname;
    const route = RouteList.find((route) => route.path === path);
    const title = route?.name || '';

    return t(title);
  };

  if (isLoadingGetMe) {
    return (
      <Box className={classes.center}>
        <CircularProgress color="error" />
      </Box>
    );
  }

  if (!token) {
    return <Navigate to={PATH.login} state={{ from: location }} replace />;
  }

  return (
    <Box className={classes.root}>
      <Topbar title={getTitle()} />
      <Divider />
      <Box className={classes.container}>
        <Sidebar />
        <Box className={classes.wrapper}>
          <Box className={classes.page}>{props.children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminLayout;
