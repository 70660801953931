import apiSlice from './api/api_slice';
import modalSlice from './common/modal/modal_slice';
import promptSlice from './common/prompt/prompt_slice';
import topBarSlice from './common/topbar/topbar_slice';
import myAccountSlice from './my_account/my_account_slice';

export const reducer = {
  promptSlice,
  modalSlice,
  topBarSlice,
  myAccountSlice,
  // last reducer require
  apiSlice,
};
