import VIFlag from '../assets/images/vi-flag.png';
import EN_US_Flag from '../assets/images/en-us-flag.png';
import { TFunction } from 'react-i18next';

export const LOCALE_LANGUAGE_LIST = (t: TFunction<'translation', undefined>) => [
  {
    id: 'vi',
    name: t('label.language.vi'),
    flag: VIFlag,
  },
  {
    id: 'en',
    name: t('label.language.en'),
    flag: EN_US_Flag,
  },
];
