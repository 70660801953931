import { LogoutOutlined } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MODAL_IDS } from 'src/constants/modal';
import { useAppDispatch } from 'src/redux_store';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import { logout } from 'src/redux_store/my_account/my_account_action';
import { changeIsReload, logoutLocal } from 'src/redux_store/my_account/my_account_slice';
import { PATH } from 'src/routes/path';
import ConfirmationDialog from '../confirm_dialog';

const ModalAcceptLogout = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const handleLogout = () => {
    dispatch(changeIsReload(false));

    dispatch(logout())
      .unwrap()
      .finally(() => {
        dispatch(logoutLocal());
        dispatch(closeModal({ modalId: MODAL_IDS.logout }));
        navigation(PATH.login);
        dispatch(changeIsReload(true));
      });
  };

  return (
    <ConfirmationDialog
      callback={handleLogout}
      describe={t('label.logout.description')}
      functionName="logout"
      modalId={`${MODAL_IDS.logout}`}
      sliceName="myAccount"
      okLabel={t('label.logout.main')}
      icon={<LogoutOutlined fontSize="large" color="primary" />}
    />
  );
};

export default ModalAcceptLogout;
