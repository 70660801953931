import React from 'react';
import { Box } from '@mui/material';
import { Route, Routes as Router } from 'react-router-dom';

import Loading from 'src/components/loading';
import { RouteList } from './route_list';

const Routes = () => {
  return (
    <Router>
      {RouteList.map((route) => {
        const Page = route.component as any;
        const Layout = route.layout ? route.layout : React.Fragment;

        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Layout>
                <React.Suspense
                  fallback={
                    <Box flex={1}>
                      <Loading />
                    </Box>
                  }
                >
                  <Page />
                </React.Suspense>
              </Layout>
            }
          />
        );
      })}
    </Router>
  );
};

export default Routes;
