import React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles';

type AlignProps = 'left' | 'right' | 'center';
type ComponentProps = 'p' | 'a' | 'span' | 'div';

interface LinkProps {
  title: string;
  href: string;
  textAlign?: AlignProps;
  component?: ComponentProps;
  onClick?: () => void;
}

function Link(props: LinkProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { title, href, textAlign = 'left', component = 'p', onClick } = props;

  const handleClick = () => {
    navigate(href);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Typography
      marginTop={1}
      textAlign={textAlign}
      component={component}
      className={classes.href}
      onClick={handleClick}
    >
      {title}
    </Typography>
  );
}

export default Link;
