import React from 'react';

import AdminLayout from 'src/layout/admin_layout';
import AuthLayout from 'src/layout/auth_layout';
import { IRoute } from 'src/types/route';
import { PATH } from './path';

const NotFound = React.lazy(() => import('src/components/not_found'));
const Login = React.lazy(() => import('src/pages/auth/login'));
const Home = React.lazy(() => import('src/pages/home'));
const Security = React.lazy(() => import('src/pages/security'));
const Device = React.lazy(() => import('src/pages/device'));
// const OrderHistory = React.lazy(() => import('src/pages/order_history'));
const ForgotPassword = React.lazy(() => import('src/pages/auth/forgot_password'));
const ResetPassword = React.lazy(() => import('src/pages/auth/reset_password'));
// const PaymentAndAddress = React.lazy(() => import('src/pages/payment_address'));
// const ServicesAndSubscriptions = React.lazy(() => import('src/pages/services_subscriptions'));
const Register = React.lazy(() => import('src/pages/auth/register'));
const VerifyEmail = React.lazy(() => import('src/pages/auth/verify_email'));
const RegisterByInvite = React.lazy(() => import('src/pages/auth/signup_user_complete'));
const ResendEmail = React.lazy(() => import('src/pages/auth/resend_email'));
const AcceptInvitation = React.lazy(() => import('src/pages/auth/accept_invitation'));

export const RouteList: IRoute[] = [
  {
    path: PATH.home,
    name: 'label.personalInfo.main',
    component: Home,
    layout: AdminLayout,
    permission: [],
    isHaveRoute: true,
  },
  {
    path: PATH.login,
    name: 'routes.login',
    component: Login,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.register,
    name: 'common.routes.userRegistration',
    component: Register,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.signupUserComplete,
    name: 'common.routes.userRegistration',
    component: RegisterByInvite,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.forgotPassword,
    name: 'routes.forgotPassword',
    component: ForgotPassword,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.resetPassword,
    name: 'routes.resetPassword',
    component: ResetPassword,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.resendEmail,
    name: 'common.routes.resendEmail',
    component: ResendEmail,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.verifyEmail,
    name: 'common.routes.verifyEmail',
    component: VerifyEmail,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.acceptInvitation,
    name: 'common.routes.acceptInvitation',
    component: AcceptInvitation,
    isHaveRoute: true,
  },
  {
    path: PATH.security,
    name: 'label.security.main',
    component: Security,
    layout: AdminLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.device,
    name: 'label.device.main',
    component: Device,
    layout: AdminLayout,
    isHaveRoute: true,
  },
  // {
  //   path: PATH.orderHistory,
  //   name: 'common.routes.orderHistory',
  //   component: OrderHistory,
  //   layout: AdminLayout,
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.paymentAndAddress,
  //   name: 'common.routes.paymentAndAddress',
  //   component: PaymentAndAddress,
  //   layout: AdminLayout,
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.servicesAndSubscriptions,
  //   name: 'common.routes.servicesAndSubscriptions',
  //   component: ServicesAndSubscriptions,
  //   layout: AdminLayout,
  //   isHaveRoute: true,
  // },
  {
    path: PATH.notFound,
    name: 'common.routes.notFound',
    component: NotFound,
    isHaveRoute: true,
  },
];
