import { ExpandLessOutlined, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { MenuList } from 'src/routes/menu';
import { IMenuRoute } from 'src/types/route';
import { ListSidebar, useStyles } from './styles';

const Sidebar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState<{
    [x: string]: boolean;
  }>({});

  const handleOpen = (name: string) => {
    let hasCollapsed;
    const hasOpened = _.has(open, name);
    if (hasOpened) {
      hasCollapsed = !open[name];
    } else {
      hasCollapsed = !hasOpened;
    }
    setOpen({
      [name]: hasCollapsed,
    });
  };

  const renderIcon = (menu: IMenuRoute) => {
    let Icon;
    if (location.pathname === menu.path) {
      Icon = menu.activeIcon;
    } else {
      Icon = menu.icon;
    }
    return <Icon />;
  };

  const renderMenuList = () => {
    return MenuList.map((menu, index) => {
      if (!menu.isHaveMenu) return;

      return (
        <React.Fragment key={index}>
          <ListItemButton
            className={classNames({
              active:
                location.pathname === menu.path ||
                !_.isEmpty(menu?.subMenu?.find((item) => item.path === location.pathname)),
            })}
            onClick={() => {
              handleOpen(menu.id);
              menu?.path && navigate(menu.path);
            }}
            classes={{ root: classes.item }}
          >
            <ListItemIcon>{renderIcon(menu)}</ListItemIcon>
            <ListItemText primary={t(menu.id)} />
            {menu?.subMenu && (
              <React.Fragment>
                {open?.[menu.id] ||
                !_.isEmpty(menu?.subMenu?.find((item) => item.path === location.pathname)) ? (
                  <ExpandLessOutlined />
                ) : (
                  <ExpandMore />
                )}
              </React.Fragment>
            )}
          </ListItemButton>
          {menu?.subMenu && (
            <Collapse
              in={
                open?.[menu.id] ||
                !_.isEmpty(menu?.subMenu?.find((item) => item.path === location.pathname))
              }
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {menu.subMenu.map((item, index) => (
                  <ListItemButton
                    className={classNames({
                      active_submenu: location.pathname === item.path,
                    })}
                    key={index}
                    sx={{ pl: 5, py: '6px' }}
                    classes={{ root: classes.item }}
                    onClick={() => {
                      navigate(item.path);
                    }}
                  >
                    <ListItemText primary={t(item.id)} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <Box className={classes.root}>
      {/* <Box>
        <ListItemButton classes={{ root: classes.menuTop }} onClick={toggleSidebar}>
          <ListItemIcon
            classes={{
              root: classes.menuIcon,
            }}
          >
            <Menu />
          </ListItemIcon>
          <ListItemText primary={t('common.sidebar.close')} />
        </ListItemButton>
      </Box> */}
      {/* <Divider /> */}
      <Box className={classes.top}>
        <Scrollbars>
          <ListSidebar sx={{ width: '90%', p: 2 }}>{renderMenuList()}</ListSidebar>
        </Scrollbars>
      </Box>
    </Box>
  );
};

export default Sidebar;
