import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Grid, Menu, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'src/assets/images/logo.png';
import { MODAL_IDS } from 'src/constants/modal';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { openModal } from 'src/redux_store/common/modal/modal_slice';
import { PATH } from 'src/routes/path';
import Link from '../link';
import ModalAcceptLogout from '../modal/modal_logout';
import { useStyles } from './styles';
// import ModalChangeAvatar from 'src/pages/home/modal_change_avatar';
import { LOCALE_LANGUAGE_LIST } from 'src/constants/i18n';
import { updateUserLocale } from 'src/redux_store/my_account/my_account_action';
import en from '../../assets/images/en-us-flag-small.png';
import vi from '../../assets/images/vi-flag-small.png';
interface IProps {
  title: string;
}

const Topbar = ({ title }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { me } = useAppSelector((state) => state.myAccountSlice);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState<{
    [x: string]: boolean;
  }>({});

  const [localeSelected, setLocaleSelected] = useState<string>('vi');

  useEffect(() => {
    const locale = Cookies.get('locale');
    setLocaleSelected(locale || 'vi');
  }, []);

  const handleOpen = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setAnchorEl(event.currentTarget);
    setOpen({ [name]: true });
  };

  const handleClose = (name: string) => {
    setAnchorEl(null);
    setOpen({ [name]: false });
  };

  const handleOpenModalLogout = () => {
    dispatch(openModal({ modalId: MODAL_IDS.logout, dialogComponent: <ModalAcceptLogout /> }));
  };

  // const handleOpenModalChangeAvatar = () => {
  //   dispatch(
  //     openModal({ modalId: MODAL_IDS.changeAvatar, dialogComponent: <ModalChangeAvatar /> }),
  //   );
  // };

  const handleChangeLocale = (locale: string) => {
    if (locale === localeSelected) return;
    dispatch(updateUserLocale({ userId: me.id, data: { locale } }))
      .unwrap()
      .then(() => {
        localStorage.setItem('i18nextLng', locale);
        window.location.reload();
      });
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" flexWrap="nowrap">
        <Box className={classes.logo}>
          <img
            src={logo}
            style={{
              height: '100%',
              objectFit: 'contain',
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
            alt="danateq.vn"
          />
        </Box>

        <Box className={classes.title}>
          <Typography fontWeight={600} variant="body1" textTransform="uppercase">
            {title}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.buttonAction}>
        <Grid container>
          <Grid item>
            <Box onClick={(e) => handleOpen(e, 'userLanguage')} sx={{ cursor: 'pointer' }}>
              <img
                src={localeSelected === 'vi' ? vi : en}
                alt="locate"
                style={{
                  width: '32px',
                  height: '32px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
              />
            </Box>
            <Popover
              id={'userLanguage'}
              open={Boolean(open?.userLanguage)}
              anchorEl={anchorEl}
              onClose={() => handleClose('userLanguage')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuList>
                {LOCALE_LANGUAGE_LIST(t).map((locale) => {
                  return (
                    <MenuItem
                      onClick={() => handleChangeLocale(locale.id)}
                      selected={locale.id === localeSelected}
                      key={locale.id}
                    >
                      <Box display={'flex'} gap={1.5} alignItems={'center'}>
                        <img width={'25px'} alt="Danapod" src={locale.flag} />
                        <Typography>{locale.name}</Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Popover>
          </Grid>
        </Grid>
        <Box>
          <Box sx={{ cursor: 'pointer' }} onClick={(e) => handleOpen(e, 'userSetting')}>
            <img
              src={me.avatarUrl}
              width={'40px'}
              height={'40px'}
              style={{ borderRadius: '50%', objectFit: 'cover' }}
              alt=""
            />
          </Box>
          <Menu
            id="userSetting"
            open={Boolean(open?.userSetting)}
            anchorEl={anchorEl}
            elevation={2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => handleClose('userSetting')}
          >
            <Box sx={{ width: '320px', padding: '0 5px' }}>
              <Box width={'100%'} mt={1} display={'flex'} alignItems={'center'}>
                <Box
                  p={0.5}

                  // sx={{
                  //     '&:hover .avatar': {
                  //       cursor: 'pointer',
                  //       filter: 'brightness(50%)',
                  //     },
                  //   }}
                  //   onClick={handleOpenModalChangeAvatar}
                >
                  <img
                    className="avatar"
                    src={me.avatarUrl}
                    width={'80px'}
                    height={'80px'}
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                    alt=""
                  />
                </Box>
                <Box
                  p={0.5}
                  ml={1}
                  sx={{
                    width: '220px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'600'}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {me.name}
                  </Typography>
                  <Typography>{me.email}</Typography>
                  <Link
                    href={PATH.home}
                    title={t('label.myAccount')}
                    onClick={() => {
                      setOpen({ userSetting: false });
                    }}
                  />
                </Box>
              </Box>

              <Box
                onClick={handleOpenModalLogout}
                flex={1}
                height={'30px'}
                display={'flex'}
                textAlign={'center'}
                alignItems={'center'}
                sx={{
                  gap: '15px',
                  padding: '15px 10px',
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'rgba(0,0,0,0.08)' },
                }}
              >
                <Box
                  height={'30px'}
                  sx={{
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  <LogoutIcon sx={{ fontSize: '25px' }} />
                </Box>
                <Box
                  height={'30px'}
                  sx={{
                    cursor: 'pointer',
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  <Typography>{t('label.logout.main')}</Typography>
                </Box>
              </Box>
            </Box>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
