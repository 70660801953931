import { ICustomer } from 'src/types/customer';
import { IJoinOrder } from 'src/types/order';
import ClientBase from './base';

export interface ClientOrdersMix {
  joinOrder: (data: IJoinOrder) => Promise<void>;
  getOwnedOrders: (userId: string) => Promise<ICustomer[]>;
}

const ClientOrder = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientOrdersMix {
    joinOrder = async (data: IJoinOrder) => {
      return this.doFetch<void>(
        `${this.getBaseRoute()}/orders/${data.orderId}/members/${data.memberId}/join_order`,
        {
          method: 'PUT',
          data: { status: data.status, memberSession: data.memberSession },
        },
      );
    };
    getOwnedOrders = async (userId: string) => {
      return this.doFetch<ICustomer[]>(`${this.getBaseRoute()}/customers/orders/owner/${userId}`, {
        method: 'GET',
      });
    };
  };

export default ClientOrder;
