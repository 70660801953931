import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import client from 'src/clients/http';
import config from 'src/config';
import i18n from 'src/i18n';
import { IUser } from 'src/types/auth';
import { getMe, login, patchUser, sendGenerateMFA, updateMFA } from './my_account_action';
import moment from 'moment';

interface IMyAccountState {
  token: string | null;
  me: IUser;
  codeMFA: { secret: string; qrCode: string };
  isReload: boolean;
  userSession: string | undefined;
}

const initialState: IMyAccountState = {
  token: '',
  codeMFA: { secret: '', qrCode: '' },
  me: {} as IUser,
  isReload: true,
  userSession: Cookies.get('USER_SESSION'),
};

const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    logoutLocal: (state) => {
      state.token = '';
      state.me = {} as IUser;
      state.userSession = undefined;
      client.setToken('');
      Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    },
    changeIsReload: (state, action) => {
      state.isReload = action.payload;
    },
    changeUserSession: (state, action) => {
      state.userSession = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.userSession = action.payload.token;
      Cookies.set('USER_SESSION', action.payload.token, {
        domain: config.domain,
      });
      client.setToken(action.payload.token);
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      const locale = action.payload.locale;
      Cookies.set('locale', locale, { path: '/', domain: config.domain });
      i18n.changeLanguage(locale);
      moment.locale(locale);

      state.token = Cookies.get('USER_SESSION') || '';
      state.me = action.payload;
    });
    builder.addCase(getMe.rejected, (state) => {
      state.token = '';
      state.me = {} as IUser;
      client.setToken('');
      Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    });
    builder.addCase(patchUser.fulfilled, (state, action) => {
      state.me = action.payload;
    });
    builder.addCase(sendGenerateMFA.fulfilled, (state, action) => {
      state.codeMFA = action.payload;
    });
    builder.addCase(updateMFA.fulfilled, (state) => {
      state.me.mfaActive = !state.me.mfaActive;
    });
  },
});

const { actions, reducer } = myAccountSlice;
export const { logoutLocal, changeIsReload, changeUserSession } = actions;
export default reducer;
