import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import client from 'src/clients/http';

import { useQueryParams } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { getMe } from 'src/redux_store/my_account/my_account_action';
import { changeUserSession } from 'src/redux_store/my_account/my_account_slice';

const Authentication = () => {
  const dispatch = useAppDispatch();
  const queryString = useQueryParams();
  const redirect = queryString.get('redirect');
  const tokenBrowser = Cookies.get('USER_SESSION');
  const { isReload, userSession } = useAppSelector((state) => state.myAccountSlice);

  // const [authToken, setAuthToken] = useState(Cookies.get('USER_SESSION'));
  let intervalCookie: any;

  const checkCookie = () => {
    const currentToken = Cookies.get('USER_SESSION');

    if (!redirect && isReload) {
      if (currentToken !== userSession) {
        dispatch(changeUserSession(currentToken));
        clearInterval(intervalCookie);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    intervalCookie = setInterval(checkCookie, 5000);
    return () => clearInterval(intervalCookie);
  }, [isReload]);

  useEffect(() => {
    if (tokenBrowser) {
      client.setToken(tokenBrowser);
      if (!redirect) {
        dispatch(getMe());
      }

      if (redirect) {
        // const redirectPath = atob(redirect);
        window.location.href = `${redirect}`;
      }
    }
  }, [tokenBrowser, dispatch, redirect]);

  return <React.Fragment />;
};

export default Authentication;
