import ClientBase from './base';
import { ILogin, IRegister, IUser } from 'src/types/auth';
import { IUserPatch } from 'src/types/my_account';

export interface ClientMyAccountMix {
  getMe: () => Promise<IUser>;

  login: (data: ILogin) => Promise<{ token: string }>;

  logout: () => Promise<any>;

  register: (data: IRegister) => Promise<{ token: string }>;

  resetPassword: (newPassword: string, token: string) => Promise<any>;

  resendEmail: (email: string) => Promise<any>;

  SignUpUserComplete: (token: string, data: IRegister) => Promise<any>;

  patchUser: (userId: string, data: IUserPatch) => Promise<IUser>;

  updateAvatar: (data: FormData) => Promise<{ id: string; path: string }[]>;

  updatePassword: (
    userId: string,
    password: string,
    newPassword: string,
  ) => Promise<{ message: string }>;

  sendForgotPasswordToEmail: (email: string) => Promise<{ message: string }>;

  sendVerifyCode: (userId: string) => Promise<{ message: string }>;

  verifyCode: (userId: string, code: string) => Promise<string>;

  verifyEmail: (token: string) => Promise<any>;

  revokeSession: (userId: string, sessionId: string) => Promise<any>;

  sendGenerateMFA: (
    userId: string,
    password: string,
  ) => Promise<{ qrCode: string; secret: string }>;

  updateMFA: (userId: string, code: string, activate: boolean) => Promise<any>;

  deleteAccount: (userId: string, passWord: string) => Promise<void>;
}

const ClientMyAccount = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientMyAccountMix {
    getMe = async () => {
      return this.doFetch<IUser>(`${this.getBaseRoute()}/users/me`, {
        method: 'get',
      });
    };
    login = async (data: ILogin) => {
      return this.doFetch<{ token: string }>(`${this.getBaseRoute()}/users/login`, {
        method: 'post',
        data: data,
      });
    };
    logout = async () => {
      return this.doFetch<any>(`${this.getBaseRoute()}/users/logout`, {
        method: 'post',
        data: null,
      });
    };
    register = async (data: IRegister) => {
      return this.doFetch<{ token: string }>(`${this.getBaseRoute()}/users/signup`, {
        method: 'post',
        data: data,
      });
    };
    resetPassword = async (newPassword: string, token: string) => {
      return this.doFetch<any>(`${this.getBaseRoute()}/users/password/reset`, {
        method: 'post',
        data: { newPassword, token },
      });
    };
    SignUpUserComplete = async (token: string, data: IRegister) => {
      return this.doFetch<any>(`${this.getBaseRoute()}/users/signup`, {
        method: 'post',
        params: { token },
        data: data,
      });
    };
    patchUser = async (userId: string, data: IUserPatch) => {
      return this.doFetch<IUser>(`${this.getBaseRoute()}/users/${userId}`, {
        method: 'patch',
        data: data,
      });
    };
    updateAvatar = async (data: FormData) => {
      return this.doFetch<{ id: string; path: string }[]>(
        `${this.getBaseRoute()}/files/upload/article`,
        {
          method: 'post',
          data: data,
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
    };
    updatePassword = async (userId: string, password: string, newPassword: string) => {
      return this.doFetch<{ message: string }>(`${this.getBaseRoute()}/users/${userId}/password`, {
        method: 'put',
        data: { password, newPassword },
      });
    };
    sendForgotPasswordToEmail = async (email: string) => {
      return this.doFetch<{ message: string }>(`${this.getBaseRoute()}/users/password/reset/send`, {
        method: 'post',
        data: { email },
      });
    };
    sendVerifyCode = async (userId: string) => {
      return this.doFetch<{ message: string }>(
        `${this.getBaseRoute()}/users/${userId}/email/verify_code/send`,
        {
          method: 'post',
          data: null,
        },
      );
    };
    resendEmail = async (email: string) => {
      return this.doFetch<any>(`${this.getBaseRoute()}/users/email/verify/send`, {
        method: 'post',
        data: { email },
      });
    };
    verifyCode = async (userId: string, code: string) => {
      return this.doFetch<string>(`${this.getBaseRoute()}/users/${userId}/email/verify_code`, {
        method: 'post',
        data: { code: code },
      });
    };
    verifyEmail = async (token: string) => {
      return this.doFetch<any>(`${this.getBaseRoute()}/users/email/verify`, {
        method: 'post',
        data: { token },
      });
    };
    revokeSession = async (userId: string, sessionId: string) => {
      return this.doFetch<any>(`${this.getBaseRoute()}/users/${userId}/sessions/revoke`, {
        method: 'post',
        data: { sessionId },
      });
    };
    sendGenerateMFA = async (userId: string, password: string) => {
      return this.doFetch<{ qrCode: string; secret: string }>(
        `${this.getBaseRoute()}/users/${userId}/mfa/generate`,
        {
          method: 'post',
          data: { password },
        },
      );
    };
    updateMFA = async (userId: string, code: string, activate: boolean) => {
      return this.doFetch<any>(`${this.getBaseRoute()}/users/${userId}/mfa`, {
        method: 'put',
        data: { code, activate },
      });
    };
    deleteAccount = async (userId: string, password: string) => {
      return this.doFetch<void>(`${this.getBaseRoute()}/users/${userId}/delete`, {
        method: 'post',
        data: { password },
      });
    };
  };

export default ClientMyAccount;
