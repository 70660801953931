import { Box, CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsRequestPending } from 'src/hooks';
import { useAppSelector } from 'src/redux_store';
import { PATH } from 'src/routes/path';
import imageBackground from '../../assets/images/background.jpg';
import { useStyles } from './style';
import i18n from 'src/i18n';
import vi from '../../assets/images/vi-flag-small.png';
import en from '../../assets/images/en-us-flag-small.png';
import config from 'src/config';
import { LOCALE_LANGUAGE_LIST } from 'src/constants/i18n';
import { useTranslation } from 'react-i18next';

const AuthLayout = ({ children }: PropsWithChildren) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {t} = useTranslation()
  const classes = useStyles();
  const isLoadingGetMe = useIsRequestPending('myAccount', 'getMe');
  const { me } = useAppSelector((state) => state.myAccountSlice);
  const token = Cookies.get('USER_SESSION');
  const locale = localStorage.getItem('i18nextLng') || 'en';

  const [localeSelected, setLocaleSelected] = useState<string>('vi');

  useEffect(() => {
    const locale = localStorage.getItem('i18nextLng') || 'en';
    setLocaleSelected(locale);
  }, []);

  document.title = i18n.t('label.danapodAccount');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log('jkh');
    setAnchorEl(null);
  };

  if (token && !_.isEmpty(me)) {
    return <Navigate to={PATH.home} replace />;
  }

  if (isLoadingGetMe) {
    return (
      <Box className={classes.center}>
        <CircularProgress color="error" />
      </Box>
    );
  }


  const handleChangeLocale = (language: string) => {
    if (locale !== language) {
      localStorage.setItem('i18nextLng', language);
      Cookies.set('locale', language, { path: '/', domain: config.domain });
      window.location.reload();
    } else {
      setAnchorEl(null);
    }
  };

  return (
    <Box
      className={classes.root}
      sx={{
        backgroundImage: `url(${imageBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: '2',
          right: '1.5%',
          top: '2.5%',
          cursor: 'pointer',
        }}
      >
        <Box
          onClick={(e: any) => {
            handleClick(e);
          }}
        >
          <img
            src={localeSelected === 'vi' ? vi : en}
            alt="locate"
            style={{
              width: '32px',
              height: '32px',
              objectFit: 'cover',
              borderRadius: '50%',
            }}
          />
        </Box>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {LOCALE_LANGUAGE_LIST(t).map((item) => {
            return (
              <MenuItem
                onClick={() => {
                  handleChangeLocale(item.id);
                }}
                key={item.id}
                selected={item.id === localeSelected}
              >
                <Box display={'flex'} gap={1.5} alignItems={'center'}>
                  <img width={'25px'} alt="Danapod" src={item.flag} />
                  <Typography>{item.name}</Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
      {children}
    </Box>
  );
};

export default AuthLayout;
