import { ISession } from 'src/types/session';
import ClientBase from './base';

export interface ClientSessionsMix {
  getUserSessions: (userId: string) => Promise<ISession[]>;
}

const ClientSession = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientSessionsMix {
    getUserSessions = async (userId: string) => {
      return this.doFetch<ISession[]>(`${this.getBaseRoute()}/users/${userId}/sessions`, {
        method: 'get',
      });
    };
  };

export default ClientSession;
