import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { toast, useToasterStore } from 'react-hot-toast';
import { I18nextProvider } from 'react-i18next';

import './App.css';
import Authentication from './components/authentication';
import ModalController from './components/modal';
import i18n from './i18n';
import Routes from './routes';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Cookies from 'js-cookie';
import moment from 'moment';
import config from './config';
import GlobalDndContext from './dnd-context';

const TOAST_LIMIT = 1;

const App = () => {
  const { toasts } = useToasterStore();

  useEffect(() => {
    const locale = localStorage.getItem('i18nextLng') || 'en';
    Cookies.set('locale', locale, { path: '/', domain: config.domain });
    i18n.changeLanguage(locale);
    moment.updateLocale(locale, null);
  }, []);

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation: ;
  }, [toasts]);

  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GlobalDndContext>
          <Authentication />
          <Routes />
          <ModalController />
        </GlobalDndContext>
      </LocalizationProvider>
    </I18nextProvider>
  );
};

export default App;
