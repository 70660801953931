import axios, { Method } from 'axios';
import config from 'src/config';

type Options = {
  headers?: { [x: string]: string };
  method: Method;
  data?: any;
  params?: any;
  signal?: AbortSignal;
};

export default class ClientBase {
  requestHeaders: { [x: string]: string } = {};
  urlVersion = '/api/v1';
  token = '';

  constructor() {}

  setToken = (token: string) => {
    if (token) {
      this.token = token;
      this.requestHeaders.Authorization = token;
    } else {
      this.token = '';
      delete this.requestHeaders.Authorization;
    }
  };

  getBaseRoute = () => {
    return `${config.baseUrl}${this.urlVersion}`;
  };

  getPoliticalRoute = () => {
    return `http://10.49.46.53:5050${this.urlVersion}/political`;
  };

  getOptions = (options: Options) => {
    const newOptions: Options = { ...options };

    const headers: { [x: string]: string } = { ...this.requestHeaders };

    if (this.token) {
      Object.assign(headers, newOptions.headers);
    }

    return { ...newOptions, headers };
  };

  doFetch = async <T>(url: string, options: Options): Promise<T> => {
    try {
      const response = await axios<T>(url, {
        withCredentials: true,
        ...this.getOptions(options),
      });

      return response.data;
    } catch (error: any) {
      return Promise.reject(error.response?.data ? error.response.data : error.response);
    }
  };
}
