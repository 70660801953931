import mix from 'src/utils/mix';
import ClientBase from './base';
import ClientPackages from './packages';
import ClientPolitical, { ClientPoliticalMix } from './political';
import ClientSession, { ClientSessionsMix } from './session';
import ClientMyAccount, { ClientMyAccountMix } from './my_account';
import ClientOrder, { ClientOrdersMix } from './order';

interface Client
  extends ClientBase,
    ClientSessionsMix,
    ClientMyAccountMix,
    ClientPoliticalMix,
    ClientOrdersMix {}

class Client extends mix(ClientBase).with(
  ClientPackages,
  ClientMyAccount,
  ClientPolitical,
  ClientSession,
  ClientOrder,
) {
  constructor() {
    super();
  }
}

const client = new Client();

export default client;
